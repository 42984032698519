import { useScrollerContext, useWatchItems } from '@graphcommerce/framer-scroller'
import { useMotionValueValue } from '@graphcommerce/framer-utils'
import { RelatedProductsFragment } from '@graphcommerce/magento-product'
import {
  RenderType,
  Row,
  breakpointVal,
  extendableComponent,
  nonNullable,
  responsiveVal,
} from '@graphcommerce/next-ui'
import { Box, Divider, SxProps, Theme, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { FullWidthSlider } from '../FullWidthSlider'
import { RowProductFragment } from '../RowProduct.gql'

type RelatedProps = RowProductFragment & RelatedProductsFragment & { sx?: SxProps<Theme> }
type RelatedItemProps = { item: RelatedProduct; index: number; highlightedSpecs: boolean }
type RelatedProduct = NonNullable<RelatedProductsFragment['related_products']>[0]

const componentName = 'RelatedProducts' as const
const parts = ['root'] as const
const { classes } = extendableComponent(componentName, parts)

export const RelatedProductSpecs = ({ item, index, highlightedSpecs }: RelatedItemProps) => {
  const [current, setCurrent] = useState(1)
  const { items } = useScrollerContext()

  useMotionValueValue(items, (itemsArr) => itemsArr.length)
  useWatchItems((_, itemArr) => {
    const visibleItems = itemArr
      .map((i, idx) => [idx + 1, i.visibility.get()])
      .filter((i) => i[1] > 0)
      .sort((a, b) => b[1] - a[1])

    setCurrent(visibleItems[0]?.[0] ?? 1)
  })
  const selected = current === index + 1

  if (!highlightedSpecs) return null

  return (
    <Box sx={(theme) => ({ marginTop: theme.spacings.sm })}>
      {item?.custom_attributes.map(
        (attributes) =>
          attributes?.attribute_metadata?.highlighted && (
            <Box
              sx={{ position: 'relative' }}
              key={`${attributes?.attribute_metadata?.code}_${index}`}
            >
              <Typography
                component='p'
                variant='overline'
                sx={(theme) => ({
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  mb: 1,
                  color: selected ? theme.palette.text.secondary : 'transparent',
                  transition: 'color 0.3s ease-in-out',
                })}
              >
                {attributes?.attribute_metadata?.label || '-'}
              </Typography>
              <Divider sx={(theme) => ({ width: `calc(100% + ${theme.spacings.md})` })} />
              <Typography
                component='p'
                variant='subtitle1'
                sx={(theme) => ({
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  mt: 1,
                  mb: theme.spacings.xs,
                })}
              >
                {attributes?.selected_attribute_options?.attribute_option?.map(
                  (value) => value?.label,
                ) || '-'}
              </Typography>
            </Box>
          ),
      )}
    </Box>
  )
}

export function Related(props: RelatedProps) {
  const { title, related_products } = props

  const hasHighlighted = related_products?.some((product) =>
    product?.custom_attributes?.some((attribute) => attribute?.attribute_metadata?.highlighted),
  )

  /** A boolean to determine if the specs should show or not */
  const showSpecs = useMemo(() => {
    /**
     * The attributes that all related products should match. In this case we can use the first
     * product because all products need the same product attributes anyway.
     */
    const specsPattern =
      related_products?.[0]?.custom_attributes
        .filter((attribute) => attribute?.attribute_metadata?.highlighted)
        ?.map((attribute) => attribute?.attribute_metadata?.code)
        .filter(nonNullable) || []

    /**
     * Contains an array of highlighted specs of each product. These are going to be matched against
     * the specsPattern.
     */
    const highlightedSpecs = related_products?.map((product) =>
      product?.custom_attributes?.filter((att) => att?.attribute_metadata?.highlighted),
    )

    /**
     * If any product doesn't have highlighted specs, we don't want to render anything. This check
     * prevents that showSpecs is true when it shouldn't be.
     */
    const highlightedSpecsMapContainsEmptyArray = highlightedSpecs?.some(
      (productAttributesArray) => productAttributesArray?.length === 0,
    )

    return (
      highlightedSpecs?.every((productAttributesArray) =>
        productAttributesArray?.every((productAttribute) =>
          specsPattern.includes(productAttribute?.attribute_metadata?.code ?? ''),
        ),
      ) && !highlightedSpecsMapContainsEmptyArray
    )
  }, [related_products])

  if (!related_products || related_products.length === 0) return null

  return (
    <Row maxWidth='lg' className={classes.root}>
      <FullWidthSlider
        sx={(theme) => ({ marginBottom: theme.spacings.md })}
        title={
          <Typography
            component='h2'
            variant='h3'
            sx={(theme) => ({
              ...breakpointVal('fontSize', 18, 30, theme.breakpoints.values),
            })}
          >
            {title} producten
          </Typography>
        }
      >
        {related_products?.map((item, index) =>
          item ? (
            <Box
              key={item.sku}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '&:last-child': {
                  '& .MuiTypography-root': {
                    '&::after': {
                      width: '100%',
                    },
                  },
                },
              }}
            >
              <RenderType
                renderer={productListRenderer}
                sizes={responsiveVal(200, 400)}
                titleComponent='h3'
                {...item}
                sx={{
                  '&.ProductListItem-root': { height: 'auto', flexGrow: 1 },
                  '& img': { mixBlendMode: 'multiply' },
                }}
              />
              {hasHighlighted && (
                <RelatedProductSpecs
                  item={item}
                  index={index}
                  highlightedSpecs={Boolean(showSpecs)}
                />
              )}
            </Box>
          ) : null,
        )}
      </FullWidthSlider>
    </Row>
  )
}
