import {
  Button,
  IconSvg,
  Row,
  extendableComponent,
  iconChevronDown,
  iconChevronUp,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Divider, Typography } from '@mui/material'
import { useState, useMemo } from 'react'
import { ProductCustomAttributesFragment } from '../../../ProductListItems/parts/ProductCustomAttributes.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { excludedAttributes } from '../constants/specsConstants'

const componentName = 'Specs' as const
const parts = ['root', 'title', 'row', 'wrapper', 'label', 'value'] as const
const { classes } = extendableComponent(componentName, parts)

const MAX_VISIBLE_ATTRIBUTES = 6

type SpecsProps = RowProductFragment & Partial<ProductCustomAttributesFragment>

export function Specs(props: SpecsProps) {
  const { custom_attributes } = props
  const [showAll, setShowAll] = useState(false)

  const filteredAttributes = useMemo(() => {
    const priorityAttributes = ['manufacturer', 'erp_internalnumber', 'erp_ean']

    return (custom_attributes ?? [])
      .filter(
        (attribute) =>
          !excludedAttributes.includes(attribute?.attribute_metadata?.code ?? '') &&
          attribute?.attribute_metadata?.is_visible_on_front,
      )
      .sort((a, b) => {
        const codeA = a?.attribute_metadata?.code ?? ''
        const codeB = b?.attribute_metadata?.code ?? ''

        const indexA = priorityAttributes.indexOf(codeA)
        const indexB = priorityAttributes.indexOf(codeB)

        if (indexA !== -1 && indexB !== -1) return indexA - indexB
        if (indexA !== -1) return -1
        if (indexB !== -1) return 1

        return 0
      })
  }, [custom_attributes])

  const visibleAttributes = showAll
    ? filteredAttributes
    : filteredAttributes.slice(0, MAX_VISIBLE_ATTRIBUTES)

  const handleToggleShowAll = () => setShowAll((prev) => !prev)

  return (
    <Row maxWidth='lg' className={classes.root}>
      <Typography
        className={classes.title}
        variant='overline'
        color='textSecondary'
        component='h2'
        sx={{ p: 1 }}
      >
        <Trans id='Product Details' />
      </Typography>
      <Divider />
      {filteredAttributes.length === 0 ? (
        <Typography variant='subtitle1' color='textSecondary'>
          <Trans id='No attributes available' />
        </Typography>
      ) : (
        <>
          <Box>
            {visibleAttributes.map((attribute) => {
              if (!attribute) return null

              const { attribute_metadata, entered_attribute_value, selected_attribute_options } =
                attribute

              return (
                <Box
                  className={classes.row}
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: 'divider',
                    py: '4px',

                    '&:hover, &:focus, &:active': {
                      '& > .Specs-wrapper': {
                        backgroundColor: 'secondary.light',
                      },
                    },
                  }}
                  key={attribute_metadata?.code}
                >
                  <Box
                    className={classes.wrapper}
                    sx={{
                      display: 'flex',
                      flexFlow: 'row wrap',
                      padding: '4px 8px',
                      borderRadius: '8px',
                    }}
                  >
                    <Box className={classes.label} sx={{ flex: 1 }}>
                      <Typography>{attribute_metadata?.label}</Typography>
                    </Box>
                    <Box
                      className={classes.value}
                      sx={(theme) => ({ flex: 1, paddingLeft: theme.spacings.md })}
                    >
                      <Typography>
                        {entered_attribute_value?.value}
                        {selected_attribute_options?.attribute_option
                          ?.map((option) => option?.label)
                          .join(', ')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Box>

          {filteredAttributes.length > MAX_VISIBLE_ATTRIBUTES && (
            <Box
              sx={(theme) => ({
                mt: theme.spacings.sm,
                textAlign: 'center',
              })}
            >
              <Button
                onClick={handleToggleShowAll}
                sx={{
                  mx: 'auto',
                  border: '1px solid',
                  borderColor: 'divider',
                }}
                variant='pill'
                disableElevation
                endIcon={<IconSvg src={showAll ? iconChevronUp : iconChevronDown} />}
              >
                {showAll ? <Trans id='Show less' /> : <Trans id='Show more' />}
              </Button>
            </Box>
          )}
        </>
      )}
    </Row>
  )
}
