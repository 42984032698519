import { Box } from '@mui/material'
import { RowColumnOne } from '../RowColumnOne/RowColumnOne'
import { RowColumnOneFragment } from '../RowColumnOne/RowColumnOne.gql'

type Props = {
  content: RowColumnOneFragment
}

export function StoreMessage(props: Props) {
  const { content } = props

  if (!content) return null

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'primary.dark',
        '& .MuiContainer-root': {
          marginBottom: 0,
          color: 'primary.contrastText',
          textAlign: 'center',
          paddingTop: `calc(${theme.spacings.xs} * 0.5)`,
          paddingBottom: `calc(${theme.spacings.xs} * 0.5)`,
        },
      })}
    >
      <RowColumnOne {...content} />
    </Box>
  )
}
