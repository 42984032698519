import { UpsellProductsFragment } from '@graphcommerce/magento-product'
import {
  RenderType,
  responsiveVal,
  breakpointVal,
  Row,
  extendableComponent,
} from '@graphcommerce/next-ui'
import { SxProps, Theme, Typography } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { FullWidthSlider } from '../FullWidthSlider'
import { RowProductFragment } from '../RowProduct.gql'

type UpsellsProps = RowProductFragment & UpsellProductsFragment & { sx?: SxProps<Theme> }

const componentName = 'UpsellProducts' as const
const parts = ['root'] as const
const { classes } = extendableComponent(componentName, parts)

export function Upsells(props: UpsellsProps) {
  const { title, upsell_products } = props

  if (!upsell_products || upsell_products.length === 0) return null

  return (
    <Row maxWidth='lg' className={classes.root}>
      <FullWidthSlider
        sx={(theme) => ({ marginBottom: theme.spacings.md })}
        title={
          <Typography
            component='h2'
            variant='h3'
            sx={(theme) => ({
              ...breakpointVal('fontSize', 18, 30, theme.breakpoints.values),
            })}
          >
            {title}
          </Typography>
        }
        buttonSize='small'
      >
        {upsell_products?.map((item) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={productListRenderer}
              sizes={responsiveVal(200, 400)}
              titleComponent='h3'
              {...item}
              sx={{ '& img': { mixBlendMode: 'multiply' } }}
            />
          ) : null,
        )}
      </FullWidthSlider>
    </Row>
  )
}
